import { Cog6ToothIcon, QuestionMarkCircleIcon } from "@heroicons/react/24/outline";
import { ArrowUturnLeftIcon } from "@heroicons/react/24/solid";
import { useIsSidebarOpen } from "~/providers/SidebarProvider";
import SidebarLink from "./SidebarLink";
export default function BottomSidebar() {
  const isSidebarOpen = useIsSidebarOpen();
  return <div className={`flex flex-col gap-1 whitespace-nowrap border-t border-divider py-5 ${isSidebarOpen ? "" : "px-3"}`} data-sentry-component="BottomSidebar" data-sentry-source-file="BottomSidebar.tsx">
      {/*<SidebarLink
        isExternal={false}
        text={"Integrations"}
        href="/integrations"
       >
        <FireIcon className="sm-icon" />
       </SidebarLink>*/}
      <SidebarLink isExternal={false} text={"Settings"} href="/settings" data-sentry-element="SidebarLink" data-sentry-source-file="BottomSidebar.tsx">
        <Cog6ToothIcon className="sm-icon" data-sentry-element="Cog6ToothIcon" data-sentry-source-file="BottomSidebar.tsx" />
      </SidebarLink>
      <SidebarLink isExternal={true} text={"Help"} href="https://openq.dev/help" data-sentry-element="SidebarLink" data-sentry-source-file="BottomSidebar.tsx">
        <QuestionMarkCircleIcon className="sm-icon" data-sentry-element="QuestionMarkCircleIcon" data-sentry-source-file="BottomSidebar.tsx" />
      </SidebarLink>
      <SidebarLink isExternal={true} text={"Back to openq.dev"} href="https://openq.dev" data-sentry-element="SidebarLink" data-sentry-source-file="BottomSidebar.tsx">
        <ArrowUturnLeftIcon className="sm-icon" data-sentry-element="ArrowUturnLeftIcon" data-sentry-source-file="BottomSidebar.tsx" />
      </SidebarLink>
    </div>;
}
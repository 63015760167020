import { ListBulletIcon } from "@heroicons/react/24/outline";
import { useRouter } from "next/router";
import { useIsSidebarOpen } from "~/providers/SidebarProvider";
import SidebarLink from "./SidebarLink";
export default function DashboardLinks() {
  const isSidebarOpen = useIsSidebarOpen();
  const router = useRouter();
  return <div className={`flex w-full flex-col gap-1 ${isSidebarOpen ? "items-start" : "px-3"} text-muted`} data-sentry-component="DashboardLinks" data-sentry-source-file="DashboardLinks.tsx">
      <SidebarLink text="Overview" href={{
      pathname: "/",
      query: {
        overview: "true"
      }
    }} isActive={router.asPath === "/"} data-sentry-element="SidebarLink" data-sentry-source-file="DashboardLinks.tsx">
        <ListBulletIcon className="sm-icon" data-sentry-element="ListBulletIcon" data-sentry-source-file="DashboardLinks.tsx" />
      </SidebarLink>
    </div>;
}
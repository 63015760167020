import { useEffect, type RefObject } from "react";
export default function useOnClickOutside(ref: RefObject<HTMLDivElement>, handler: () => void) {
  useEffect(() => {
    // Courtesy of https://stackoverflow.com/questions/32553158/detect-click-outside-react-component
    function handleClickOutside(event: MouseEvent) {
      if (ref.current && !ref?.current.contains((event.target as HTMLDivElement))) {
        handler();
      }
    }

    // Bind the event listener
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      // Unbind the event listener on clean up
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [ref, handler]);
}
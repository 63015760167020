import { type ReactNode } from "react";
import { LayoutBreadcrumbs } from "./Layout";
import Profile from "./Profile";
export default function NavBar({
  children
}: Readonly<{
  children?: ReactNode | ReactNode[];
}>) {
  return <div className="sticky z-10 flex min-h-12 w-full content-center items-center justify-between px-8" data-sentry-component="NavBar" data-sentry-source-file="NavBar.tsx">
      <LayoutBreadcrumbs data-sentry-element="LayoutBreadcrumbs" data-sentry-source-file="NavBar.tsx">{children}</LayoutBreadcrumbs>
      {/* <DarkModeSwitch />*/}
      <Profile data-sentry-element="Profile" data-sentry-source-file="NavBar.tsx" />
    </div>;
}
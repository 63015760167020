import Image from "next/image";
import { useIsSidebarOpen } from "~/providers/SidebarProvider";
import { useTeamAccountUser } from "~/providers/TeamAccountUserProvider";
import TeamSelector from "./TeamSelector";
import Link from "next/link";
export default function IdentityManagement() {
  const [teamAccountUser] = useTeamAccountUser();
  const isSidebarOpen = useIsSidebarOpen();
  return <div className="flex gap-2" data-sentry-component="IdentityManagement" data-sentry-source-file="IdentityManagement.tsx">
      <div className="h-10 w-10 rounded-md  bg-offblack p-2">
        <Link href="/" data-sentry-element="Link" data-sentry-source-file="IdentityManagement.tsx">
          <Image width="40" height="40" alt="openq-logo" src="/openq-logo-white.png" data-sentry-element="Image" data-sentry-source-file="IdentityManagement.tsx" />
        </Link>
      </div>
      {isSidebarOpen && <div>
          <TeamSelector />
          <div className="text-normal w-36 truncate leading-none">
            {teamAccountUser?.firstName || teamAccountUser?.lastName ? teamAccountUser?.firstName + " " + teamAccountUser?.lastName : teamAccountUser?.name}
          </div>
        </div>}
    </div>;
}
import { useIsSidebarOpen } from "~/providers/SidebarProvider";
import DashboardLinks from "~/components/layout/Sidebar/DashboardLinks";
import IdentityManagement from "~/components/layout/Sidebar/IdentityManagement";
import BottomSidebar from "~/components/layout/Sidebar/BottomSidebar";
import ListSidebar from "~/components/layout/Sidebar/ListSidebar";
import ToggleSidebarButton from "~/components/layout/Sidebar/ToggleSidebarButton";
export default function Sidebar() {
  const isSidebarOpen = useIsSidebarOpen();
  return <>
      <div className={`h-[calc(100vh-10px)]  z-40 ${isSidebarOpen ? "w-[320px] px-4" : "w-20"}`}></div>
      <div className={`flex h-[calc(100vh-10px)] z-40 fixed left-0 flex-none flex-col justify-between bg-gray-100 text-gray-700 dark:bg-gray-800 dark:text-gray-300 ${isSidebarOpen ? "w-[320px] px-4" : ""}`}>
        <div className="flex flex-col content-center items-center gap-5 whitespace-nowrap border-b border-divider py-4">
          <div className={`flex ${isSidebarOpen ? "w-full " : "px-4"} justify-between`}>
            <IdentityManagement data-sentry-element="IdentityManagement" data-sentry-source-file="index.tsx" />
            {isSidebarOpen && <ToggleSidebarButton />}
          </div>
          {isSidebarOpen ? <></> : <ToggleSidebarButton />}

          <DashboardLinks data-sentry-element="DashboardLinks" data-sentry-source-file="index.tsx" />
        </div>
        <ListSidebar data-sentry-element="ListSidebar" data-sentry-source-file="index.tsx" />
        <BottomSidebar data-sentry-element="BottomSidebar" data-sentry-source-file="index.tsx" />
      </div>
    </>;
}
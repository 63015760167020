import { ChartPieIcon, PlusIcon, PresentationChartLineIcon } from "@heroicons/react/24/outline";
import { ChevronDownIcon, ChevronUpIcon } from "@heroicons/react/24/solid";
import { type List } from "@openqlabs/drm-db";
import Link from "next/link";
import { useRouter } from "next/router";
import { useEffect, useState } from "react";
import { useLists } from "~/providers/ListsProvider";
import { useIsSidebarOpen } from "~/providers/SidebarProvider";
import Button from "../../base/Button";
import SidebarLink from "./SidebarLink";
export default function ListSidebar() {
  const [expanded, setExpanded] = useState(true);
  const {
    lists
  } = useLists();
  const [sortedLists, setSortedLists] = useState<List[]>([]);
  const listId = useRouter().query.listId;
  const isSidebarOpen = useIsSidebarOpen();
  useEffect(() => {
    const sortLists = (listA: List, listB: List) => {
      return listA.createdAt.getMilliseconds() - listB.createdAt.getMilliseconds();
    };
    const sortedLists = [...lists]?.sort(sortLists);
    setSortedLists(sortedLists);
  }, [lists, setSortedLists]);
  return <div className={`flex flex-1 flex-col gap-5 overflow-y-auto py-5 ${isSidebarOpen ? "" : "items-center"} text-left`} data-sentry-component="ListSidebar" data-sentry-source-file="ListSidebar.tsx">
      {isSidebarOpen ? <div className={`flex rounded-md p-1 text-left text-muted hover:bg-gray-200/50`}>
          <Link className="flex flex-1 items-center gap-2" href={"/lists"}>
            <PresentationChartLineIcon className="sm-icon ml-2" />
            <span>Lists</span>
          </Link>
          <Button onClick={() => setExpanded(!expanded)} className="mr-1 h-8 bg-gray-200/50 text-gray-400 hover:bg-gray-300/50">
            {expanded ? <ChevronUpIcon className="h-4 w-4" /> : <ChevronDownIcon className="h-4 w-4" />}
          </Button>
          <Link href="/lists/new">
            <Button className="h-8">
              <PlusIcon className="h-4 w-4" />
            </Button>
          </Link>
        </div> : <button onClick={() => setExpanded(!expanded)}>
          <PresentationChartLineIcon className="sm-icon" />
        </button>}
      <div className={`flex grow flex-col overflow-y-auto ${isSidebarOpen ? "gap-1" : "gap-3"} ${expanded ? "block" : "hidden"}`}>
        {sortedLists.map(list => <div className="w-full" key={list.id}>
            {isSidebarOpen ? <SidebarLink isActive={list.id === listId} text={list.name} href={`/lists/${list.id}`}>
                <div>
                  <ChartPieIcon className="sm-icon" />
                </div>
              </SidebarLink> : <Link href={`/lists/${list.id}`} as={`/lists/${list.id}`}>
                <div className={`flex h-10 w-10 content-center items-center justify-center rounded-full border-2 bg-white leading-none text-offblack transition-all ${list.id === listId ? "border-gray-300" : "border-white"}`}>
                  {list.name.slice(0, 1)}
                </div>
              </Link>}
          </div>)}
        {!isSidebarOpen && <div className="flex h-10 w-10 content-center items-center justify-center rounded-full bg-white leading-none">
            <Link href="/lists/new">
              <PlusIcon className="sm-icon" />
            </Link>
          </div>}
      </div>
    </div>;
}
import { ArrowLeftStartOnRectangleIcon, Cog6ToothIcon } from "@heroicons/react/24/outline";
import { signOut, useSession } from "next-auth/react";
import Image from "next/image";
import Link from "next/link";
import { useRef, useState } from "react";
import useOnClickOutside from "~/hooks/useOnClickOutside";
import { useLogger } from "~/providers/LoggerProvider";
import ChevronCorrectIcon from "./ChevronCorrectIcon";
export default function Profile() {
  const {
    data
  } = useSession();
  const [showModal, setShowModal] = useState(false);
  const logger = useLogger();
  const ref = useRef(null);
  useOnClickOutside(ref, () => setShowModal(false));
  if (!data) return null;
  if (!data.user.image || !data.user.name) return null;
  const openModal = () => {
    setShowModal(!showModal);
  };
  const handleSignout = () => {
    signOut().catch(err => logger.error(err, "Profile.tsx"));
  };
  return <div ref={ref} className="relative z-10 flex gap-1" data-sentry-component="Profile" data-sentry-source-file="Profile.tsx">
      <button onClick={openModal}>
        <Image width={30} height={30} className="rounded-full" alt={data.user.name} src={data.user.image} data-sentry-element="Image" data-sentry-source-file="Profile.tsx" />
      </button>
      <button onClick={openModal}>
        <ChevronCorrectIcon open={showModal} className="relative top-1 h-4 w-4 stroke-dark" data-sentry-element="ChevronCorrectIcon" data-sentry-source-file="Profile.tsx" />
      </button>
      {showModal && <ul className="list-style-none absolute right-0 top-9 z-50 w-48 cursor-pointer space-y-4 rounded-md border border-vlight bg-offwhite p-6 font-medium shadow-lg">
          <li>
            <Link className="flex w-full justify-between" href="/settings">
              Settings
              <Cog6ToothIcon className="sm-icon" />
            </Link>
          </li>
          <li>
            <button className="flex w-full justify-between" onClick={handleSignout}>
              Sign out <ArrowLeftStartOnRectangleIcon className="sm-icon" />
            </button>
          </li>
        </ul>}
    </div>;
}